import styled from "styled-components";

import { useAccountLayoutContext } from "~/components/layouts/AccountLayout/AccountLayoutProvider";
import { safeDynamicImport } from "~/utils/import/dynamic";

const HomeDesktop = safeDynamicImport(() => import("./HomeDesktop"), {
  ssr: false
});
const HomeMobile = safeDynamicImport(() => import("./HomeMobile"), {
  ssr: false
});
const PostsFeed = safeDynamicImport(
  () => import("~/components/posts/PostsFeed"),
  {
    ssr: false
  }
);

const Home = (): JSX.Element | null => {
  const { authorized, authorizationStatusFetched, mobileView } =
    useAccountLayoutContext();

  if (!authorizationStatusFetched) {
    return null;
  }

  if (authorized) {
    return (
      <Wrapper>
        <PostsFeed />
      </Wrapper>
    );
  }

  return <Wrapper>{mobileView ? <HomeMobile /> : <HomeDesktop />}</Wrapper>;
};

export default Home;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
