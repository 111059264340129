
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { NextPage } from "next";

import Home from "~/components/auth/home/Home";
import AccountLayoutIndex from "~/components/layouts/AccountLayout/AccountLayoutIndex";
import withLayoutOverride from "~/components/layouts/withLayoutOverride";
import Meta from "~/components/meta/Meta";

const IndexPage: NextPage = (): JSX.Element => (
  <>
    <Meta pageKey="index" />
    <Home />
  </>
);

const __Page_Next_Translate__ = withLayoutOverride({
  hideHeaderForUnauthorizedUser: true,
  subLayout: AccountLayoutIndex({
    hideForUnauthorizedUser: true
  })
})(IndexPage);


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  