import Head from "next/head";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { Fragment } from "react";

import ROUTES from "~/constants/routes";

import { DEFAULT_META_IMAGES } from "./constants";
import { MetaImage } from "./declarations";
import useDefaultMetaAttributes from "./useDefaultMetaAttributes";

const HOST_URL = process.env.NEXT_PUBLIC_HOST_URL;

export type Props = {
  title?: string;
  desc?: string;
  keywords?: string;
  images?: MetaImage[];
  pageKey?: string;
  contentType?: "article" | "video" | "website";
};

const Meta = ({
  pageKey,
  title: explicitTitle,
  desc: explicitDesc,
  keywords: explicitKeywords,
  contentType = "website",
  images = DEFAULT_META_IMAGES
}: Props): JSX.Element => {
  const { t } = useTranslation("common");
  const { pathname, asPath } = useRouter();

  const {
    title: defaultTitle,
    desc: defaultDesc,
    keywords: defaultKeywords
  } = useDefaultMetaAttributes(pageKey);

  const title = explicitTitle || defaultTitle;
  const desc = explicitDesc || defaultDesc;
  const keywords = explicitKeywords || defaultKeywords;

  const metaParts = [
    ...(title ? [title, t("title-splitter")] : []),
    t("app-name")
  ];

  if (ROUTES.posts.discover.match(pathname)) {
    metaParts.reverse();
  }

  const metaTitle = metaParts.join("");
  const absoluteUrl = `${HOST_URL}${asPath}`;

  return (
    <Head>
      <title>{metaTitle}</title>
      <meta name="description" content={desc} />
      <meta name="keywords" content={keywords} />
      <meta property="og:url" content={absoluteUrl} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={desc} />
      <meta property="og:type" content={contentType} />
      <meta property="og:site_name" content={t("app-name")} />

      {images?.map(image => (
        <Fragment key={image.url}>
          <meta property="og:image" content={image.url} />
          <meta property="og:image:width" content={`${image.width}`} />
          <meta property="og:image:height" content={`${image.height}`} />
        </Fragment>
      ))}
    </Head>
  );
};

export default Meta;
